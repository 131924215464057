nav {
  display: none;
  height: inherit !important;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;

  @media only screen and (max-width: $meduim-max-width) {
    border-bottom: $red-strip solid $primary-red;
  }

  .donate {
    background-color: $primary-blue;
    visibility: visible;

    a {
      color: $white !important;
    }
  }

  @media only screen and (max-width: $meduim-max-width) {
    .title-bar {
      background-color: $white;
      padding: .47rem;

      img {
        margin-left: 1rem;
      }
    }

    .sticky.is-stuck {
      @extend .mobile-hidden-menu;
    }

    .sticky.is-stuck.is-at-top {
      @extend .mobile-hidden-menu-top;
    }

    #donate {
      background-color: $primary-blue;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem 1.5rem;

      li {
        list-style: none;
        padding: $none;
      }

      a {
        color: $white;
      }
    }
  }

  //placed outside of @media so extend works.
  //area not visible yet
  .title-bar {
    @extend .word-style-3;
  }

  //placed outside of @media so extend works.
  //area not visible yet
  #donate {
    @extend .word-style-3;
  }

  .top-bar {
    @extend .word-style-3;
    @extend .strip-bottom;
    padding: $none;
    margin-top: 0 !important;

    .dropdown {
      @extend .center;

      li {
        margin: size($base-size) 0 size(15) 0;
        height: 33px !important;

        @media only screen and (max-width: $meduim-max-width){
          height: inherit !important;
        }
      }

      li:first-child {
        margin: 0 !important ;
        padding-right: size($base-size) !important;
      }

      li:last-child {
        margin: 0 !important ;
        padding: size($base-size) 0 size(48) 0;
      }

      li a {
        padding-right: 1rem;
        padding-top: .4rem;
        white-space: nowrap;
      }

      .menu-logo {
        display: table-cell;
        padding: 5px 0 3px 0;
        width: 70.92px;
        height: 59px;
        content: "";
        background: url($rider-logo) no-repeat 0 5px;
        background-size: 100%;

        @media only screen and (max-width: $meduim-max-width) {
          display: none;
        }
      }

      .is-dropdown-submenu-parent {
        /*position cheveron*/
        span::after {
          font-family: $font-awesome;
          font-weight: $extra-bold;
          font-size: $base-size;
          content: "\f107";
          padding: size(8) size(16) size(8) size(5);
          top: .1rem;
          border: none;
          margin: $none;
        }
      }

      .is-dropdown-submenu-non-parent{
        span{
          position: relative;
          top: -5px;
        }
      }

      .is-dropdown-submenu-item {
        a::after {
          content: "";
        }
      }

      .foalpatrol, .giftshop {
        margin-left: 102px;

        @media only screen and (max-width: $meduim-max-width) {
          margin: 0 !important;
        }
      }
    }

    .vertical {
      text-align: left;
      margin: 0 0 size(8) 0;

      li:last-child {
        margin: size($base-size) 0 size(15) 0 !important;
        padding: 0;
      }
    }

    @media only screen and (max-width: $meduim-max-width) {
      font-size: $font-16;
      border: none;
      @extend .mobile-top-bar;
      @extend .mobile-hidden-menu;
      @extend .mobile-hidden-menu-top;

      .dropdown {
        display: block;
        width: 100%;
        text-align: left;
        margin: $none;
      }

      .dropdown::before {
        display: none;
        content: "";
      }

      .is-dropdown-submenu.js-dropdown-active {
        position: relative;
      }

      .dropdown {
        .foalpatrol, .giftshop {
          margin: 0;

          span a {
            padding: 0;
            display: inline-block;
          }
        }

        li a {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: size(16);
          padding-right: 0;
        }

        li:first-child{
          padding-top: size(8);
        }

        li:last-child {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: size(16);
          padding-right: 0;

          a {
            padding-left: 0;
          }
        }

        span {
          padding-left: size(8);
        }

        .donate {
          display: none;
        }
      }
    }
  }
}