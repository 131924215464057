.word-style-1 {
  font-family: $serif;
  font-weight: $normal;
  font-size: $font-16;
  line-height: $line-spacing-24;
  letter-spacing: $char-space;
  color: $black;
}

.word-style-2 {
  @extend .word-style-1;
  line-height: $line-spacing-32;
  color: $blue;
  text-transform: uppercase;
}

.word-style-3 {
  @extend .word-style-2;
  font-size: $font-24;
}

.word-style-4 {
  font-family: $sans-serif;
  font-style: $semi-bold;
  font-size: $font-24;
  line-height: $line-spacing-32;
  letter-spacing:$char-space;
  color: $primary-blue;
}

.word-style-5 {
  @extend .word-style-4;
  font-size: $font-32;
  line-height: $line-spacing-40;
}

.word-style-6 {
  @extend .word-style-4;
  font-size: $font-40;
  line-height: $line-spacing-48;
}

.word-style-7 {
  @extend .word-style-4;
  font-size: $font-48;
  line-height: $line-spacing-56;
}

.word-style-8 {
  @extend .word-style-2;
  font-size: $font-16;
}

.base-background-color {
  background-color: $grey;
}

// Global
body {
  @extend .base-background-color;
  @extend .word-style-1;
  margin: $none;
  -webkit-text-size-adjust: 100%;  //fix for iOS
}

a {
  color: $primary-blue;
}

h1 {
  @extend .word-style-7;
}

h2 {
  @extend .word-style-6;
}

h3 {
  @extend .word-style-5;
}

h4 {
  @extend .word-style-4;
}

select {
  width: inherit;
}

input[type=submit]{
  background-color: $primary-blue;
  color: $white;
  text-decoration: none;
  cursor: pointer;
  padding: size(16);
  border: none;
}

.center {
  text-align: center; //for text
  margin: auto; //for divs
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.full-page {
  max-width: 100%;
  min-width: 100%;
  margin: $none;
}

.strip-top {
  border-top: $red-strip solid $primary-red;
}

.strip-bottom {
  border-bottom: $red-strip solid $primary-red;
}

.overlay-image-centered {
  display:block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

.nmr-content {
  max-width: $large-breakpoint !important;

  p {
    padding-bottom: size(16);
    margin: 0px;
  }

  p:last-child {
    padding-bottom: size(32);
  }

  /*used to streatch image for extra large screens*/
  @media only screen and (min-width: $large-breakpoint) {
    width: $large-breakpoint !important;
  }

  /*when screen below max width make sure elements take up whole screen*/
  @media only screen and (max-width: $meduim-max-width) {
    min-width: 100%;
  }
}

.row {
  max-width: 100%;
}

.seperate-spacing-bottom {
  margin-bottom: size(24);
}

.seperate-spacing-top {
  margin-top: size(24);
}

.wrap-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.gallery {
  max-width: 444px;
}

.gallery-text {
  @extend .wrap-text;
  @extend .left;
  padding: size(16);
}

.data-link:hover {
  cursor: pointer;
}

#photo-finish {
  .gallery-item {
    padding: size(8) size(16);

    img {
      min-height: $lightbox-image-size-large;
      max-height: $lightbox-image-size-large;
      min-width: $lightbox-image-size-large;
      max-width: $lightbox-image-size-large;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: $meduim-max-width) {
  .mobile-top-bar {
    max-width: 25rem !important;
  }

  .mobile-hidden-menu {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
  }

  .mobile-hidden-menu-top{
    top: 66px !important;
  }

  #photo-finish .gallery-item img {
    min-height: $lightbox-image-size-medium;
    max-height: $lightbox-image-size-medium;
    min-width: $lightbox-image-size-medium;
    max-width: $lightbox-image-size-medium;
  }

  .hofSearch {
    max-width: 75% !important;
    input {margin: .5rem}
    button {margin: 0 .5rem !important;}
  }
}

@media only screen and (max-width: $small-max-width) {
  .base-header {
    font-family: $sans-serif;
    font-style: $semi-bold;
    letter-spacing:$char-space;
    color: $primary-blue;
  }

  h1 {
    @extend .base-header;
    font-size: $font-22;
    line-height: $line-spacing-26;
  }

  h2 {
    @extend .base-header;
    font-size: $font-18;
    line-height: $line-spacing-22;
  }

  h3 {
    @extend .base-header;
    font-size: $font-14;
    line-height: $line-spacing-18;
  }

  h4 {
    @extend .base-header;
    font-size: $font-10;
    line-height: $line-spacing-14;
  }

  #photo-finish .gallery-item img {
    min-height: $lightbox-image-size-small;
    max-height: $lightbox-image-size-small;
    min-width: $lightbox-image-size-small;
    max-width: $lightbox-image-size-small;
  }
}