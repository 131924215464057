@import 'base/variables';

@import 'foundation';

@import 'fontawesome';
@import "brands";
@import "regular";
@import "solid";
@import "v4-shims";

@import 'slick.scss';
@import 'lightgallery.scss';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;
/*@include foundation-xy-grid-classes;*/
@include foundation-typography;
/*@include foundation-button;*/
/*@include foundation-forms;*/
/*@include foundation-range-input;*/
/*@include foundation-accordion;*/
/*@include foundation-accordion-menu;**/
/*@include foundation-badge;*/
/*@include foundation-breadcrumbs;*/
/*@include foundation-button-group;*/
/*@include foundation-callout;*/
/*@include foundation-card;*/
/*@include foundation-close-button;*/
@include foundation-menu;
@include foundation-menu-icon;
/*@include foundation-drilldown-menu;*/
/*@include foundation-dropdown;*/
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
/*@include foundation-label;*/
/*@include foundation-media-object;*/
/*@include foundation-off-canvas;*/
/*@include foundation-orbit;*/
/*@include foundation-pagination;*/
/*@include foundation-progress-bar;*/
/*@include foundation-progress-element;*/
/*@include foundation-meter-element;*/
/*@include foundation-slider;*/
@include foundation-sticky;
/*@include foundation-reveal;*/
/*@include foundation-switch;*/
/*@include foundation-table;*/
@include foundation-tabs;
/*@include foundation-thumbnail;*/
@include foundation-title-bar;
/*@include foundation-tooltip;*/
@include foundation-top-bar;
@include foundation-visibility-classes;
/*@include foundation-float-classes;*/
/**@include foundation-flex-classes;/
/*@include foundation-prototype-classes;*/

@import 'base/typography';
@import 'layout/nav';
@import 'layout/main';
@import 'layout/tabs';
@import 'layout/footer';
@import 'layout/video';
@import 'layout/webform';
@import 'layout/lightgallery';