footer {

  .footer-navigation {
    @extend .full-page;
    background-color: $white;
    padding-top: 1rem;

    .footer-content{
      margin-bottom: 0;
    }

    .footer-menu {
      display: table;
      margin: 0 auto;

      li {
        list-style: none outside none;
        float: left;
        text-transform: uppercase;
        padding: $none;
      }

      li:not(:first-child)::before {
        content: "\2022";
        color: $primary-blue;
        margin: size(8);
      }

      li a {
        text-align: center;
        text-decoration: none;
      }
    }

    i {
      color: $primary-red;
      position: relative;
      top: -.2rem;
      left: -.2rem;
      font-size: $font-24;
    }

    img {
      margin-bottom: size(24);
    }

    .word-style-3 {
      line-height: size(29);
      display: inline-block;
      color: $primary-red;
      text-transform: capitalize;
      margin: $none;
      position: relative;
      top: -(size(4));
    }

    .footer-features {
      line-height: size(18);
      margin-top: size(16);
    }

    .social-media {
      color: $primary-blue;
      font-size: $font-32;
      padding: size(4);
    }

    @media only screen and (max-width: $small-max-width){
      .footer-features div:not(:first-child) {
        margin-top: size(20);
      }
    }
  }

  .footer-copyright {
    p{
      margin-top: size(8);
      font-weight: $medium;
    }

    .tjcis {
      color: $primary-blue;
      font-weight: bold;
    }

    img {
      height: size(64);
    }
  }
}
