@import url('https://use.typekit.net/hgv3lbe.css');

/**
* functions
*/
@function size($target) {
  @return ($target / $base-size) * 1rem;
}

/**
* Colors
*/
$white: #ffffff;
$blue: #004B87;
$black: #000000;
$primary-red: #E4002B;
$primary-blue: #094B87;
$grey: #F3F3F7;
$medium-grey: #e4e4ed;
$dark-grey: #d4d4e3;


/**
* Font
*/
$base-size: 16;
$serif: 'open-sans', serif;
$sans-serif: 'montserrat', sans-serif;
$lg-path-fonts: '../webfonts'!default; /*set directory for lightgallery*/


// Weights
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 900;

//font-sizes
$font-10: size(10);
$font-14: size(14);
$font-16: size(16);
$font-18: size(18);
$font-22: size(22);
$font-24: size(24);
$font-28: size(28);
$font-32: size(32);
$font-40: size(40);
$font-48: size(48);

//line spacing
$line-spacing-14: size(14);
$line-spacing-16: size(16);
$line-spacing-18: size(18);
$line-spacing-22: size(22);
$line-spacing-24: size(24);
$line-spacing-26: size(26);
$line-spacing-32: size(32);
$line-spacing-40: size(40);
$line-spacing-48: size(48);
$line-spacing-56: size(56);

//letter spacing
$char-space: size(0);

$none: size(0);

$gallery-image-size: 444px;
$lightbox-image-size-large: 330px;
$lightbox-image-size-medium: 250px;
$lightbox-image-size-small: 150px;


$font-awesome: "Font Awesome 5 Free";
$rider-logo: '../images/nmrLogoRider.svg';
$logo-no-rider: '../images/nmrLogoNoRider.svg';

$topbar-background: $white;

$small-breakpoint: 0px;
$medium-breakpoint: 800px;
$large-breakpoint: 1364px;
$meduim-max-width: calc(#{$large-breakpoint} - 1px);
$small-max-width: calc(#{$medium-breakpoint} - 1px);

/*Reset foundation breakpoints*/
$breakpoints: (
  small: $small-breakpoint,
  medium: $medium-breakpoint,
  large: $large-breakpoint
) !default;
/*Set gutter for grid*/
$grid-column-gutter: 16px;
$tab-content-padding: 0;

$red-strip: .38rem;
