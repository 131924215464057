.webform-container-inline {
  text-align: left;

  label {
    text-align: right;
    display: inline-block;
    width: 100%;
    @extend .large-2
  }
}
