iframe {
  border: none;
}

.video, .hof-video, .lib-video, .video-popup {
  position: relative;

  img, iframe {
    aspect-ratio: 16/9;
    width: 100%;
  }
}
.video {
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

.video-popup-hidden {
  display: none;
}

.play-button {
  font: normal normal 600 3rem/1 "Font Awesome 5 Free" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  padding: 1rem 1rem 1rem 1.3rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  color: $white;
}

.hof-video, .lib-video, .video-popup {
  padding: 1rem;

  img {
    padding: 1rem;
  }

  .video-overlay {
    width: 100%;
    background: rgba(0,0,0,.75);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;

    .video-box {
      position: fixed;
      width: 90%;
      left: 50%;
      top:50%;
      transform: translateY(-50%) translateX(-50%);
      padding: 2rem 5%;
      background: $white;
      text-align: center;
      border-radius: 5px;

      .fa-times-circle {
        font: normal normal 600 3rem/1 "Font Awesome 5 Free" !important;
        position: absolute;
        top: 2%;
        right: 2%;
      }
    }
  }

  .video-overlay-hidden {
    display: none;
  }
}
