main {

  .achievement-teaser{
    p:first-of-type {
      padding-top: size(16);
      padding-bottom: 0;
    }

    p:last-child {
      padding-bottom: size(16);
    }
  }

  .banner {
    @extend .full-page;
    @extend .strip-bottom;
    padding: $none;
    position: relative;
    z-index: -10;/*allows sub menu to show over relatively positioned content*/

    .bannerBackground {
      width: 100%;
      display: block;
      margin-bottom: -1px;
    }

    .filterOverlay {
      @extend .overlay-image-centered;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
    }

    .overlay-image {
      @extend .overlay-image-centered;
      top: 12.5%;
      height: 75%;
    }
  }

  .blog p:first-of-type, .blog-teaser p:first-of-type{
    padding-top: 0 !important;
  }

  .blog .links {
    margin-left: 0 !important;

    li {
      padding: 0 !important;
    }
  }

  .blog-teaser p:first-of-type{
    padding-top: size(8);
  }

  .article-image, .blog-image {
    padding: 0 size(8) size(8) 0;
  }

  #content {
    padding: $none;

    .layout-1col-content, .stacked-layout {
      @extend .left;
    }

    .bio {
      @extend .seperate-spacing-bottom;
      display: none;

      .nmr-content {
        text-align: left;
      }

      @media only screen and (max-width: $meduim-max-width) {
        max-width: 100%;
      }
    }

    .bio-button, #hofSearchButton, #hofClearButton {
      display: flex;
      border: thin solid $primary-blue;
      text-transform: uppercase;
      color: $primary-blue;
      padding: size(8);
      margin: 0 auto;

      i {
        padding-left: .5rem;
      }
    }

    .bio-teaser p {
      padding: 0;
      margin-bottom: size(8);
      text-align: left;
    }

    figcaption {
      background-color: #d9d5ce;
      padding: size(4);
    }

    .gallery {
      @extend .center;
      background-color: $white;
      display: inline-block;
      margin: size(8);

      img {
        width: $gallery-image-size;
      }
    }

    #hofSearchButton, #hofClearButton, .hofSearch h4 {
      display: inline-flex;
    }

    .media-gallery {
      @extend .center;
      max-width: $large-breakpoint;

      $masonry-css-column-gap: $global-margin;

      .masonry-css {
        column-count: 1;
        column-gap: $masonry-css-column-gap;

        @include breakpoint($medium-breakpoint) {
          column-count: 2;
        }

        @include breakpoint($large-breakpoint) {
          column-count: 3;
        }
      }

      .masonry-css-item {
        break-inside: avoid;
        margin-bottom: $masonry-css-column-gap;
      }
/*
      .row .column {
        background-color: $medium-grey;
        margin: 0 .25rem 1rem .25rem;
      }

      .row .column span {
        display: block;
        margin: .5rem;
      }*/
    }

    .no-bullets {
      list-style: none;
      margin: 0;
    }

    .page-title, .pane-title {
      padding: 1rem 0;
      text-align: left;

      @media only screen and (max-width: $meduim-max-width) {
        max-width: 95%;
      }
    }

    div[class^="pane-webform-client-block"] .nmr-content,
    div[class*=" pane-webform-client-block"] .nmr-content {
      width: inherit !important;
    }

    .profile {
      @extend .full-page;
      @extend .strip-top;
      @extend .strip-bottom;
      background-size: cover;
      background-position: center center;

      .columns {
        .row {
          @extend .center;
          margin: size(24);
          color: $white;

          h4 {
            color: $white;
          }
        }
      }
    }

    .race-record {
      width: 100%;

      .chart-legend {
        display: inline-block;

        li {
          @extend .center;
          list-style: none;
          padding: size(12) 0px;

          h4 {
            display: inline-block;
            padding-right: size(8);
          }

          i {
            vertical-align: 25%;
          }

          @media only screen and (max-width: $small-max-width) {
            float: left;
            padding: .75rem;

            h4 {
              font-size: $font-24;
              line-height: $line-spacing-32;
            }
          }
        }
      }

      .canvas {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        h2 {
          position: absolute;
          //left: 37%;
          width: 100%;
          text-align: center;
          top: 35%;

          @media only screen and (max-width: $small-max-width) {
            font-size: $font-40;
            line-height: $line-spacing-48;
          }
        }

        p {
          position: absolute;
          width: 100%;
          text-align: center;
          top: 50%;
          padding: 0;
        }

        @media only screen and (max-width: $small-max-width) {
          margin-bottom: 1.5rem;
        }
      }

      .record-table {
        @extend .center;
        width: 90%;

        th {
          @extend .word-style-4;
          border-color: $primary-blue;
        }

        td {
          @extend .word-style-1;
          padding: .75rem .5rem;
        }
      }
    }

    .rental-location-link {
      margin: 0 0 size(32) 0;
    }

    .tabs{
      @extend .center;
      @extend .base-background-color;
      border: $none;
      border-bottom: 3px solid $primary-blue;
      text-transform: uppercase;
      margin-top: $line-spacing-32;

      .is-active {
        pointer-events: none;
      }

      .tabs-title {
        float: none !important;
        display: inline-block;

        a {
          @extend .word-style-4;
          line-height: $line-spacing-16;
        }

        a[aria-selected='true'] {
          background: $primary-blue;
          color: $white;
        }
      }
    }

    .tabs-content {
      @extend .base-background-color;
      border: $none;
    }

    .teaser {
      padding: $line-spacing-26 0px;
    }
  }

  .destination-url{
    display: none;
  }

  .drupal-tabs {
   .primary {
      border-bottom: 1px solid $black;
      background-color: $grey;

      li a {
        color: $black;
      }

      li.active a {
        @extend .base-background-color;
      }
    }
  }

  .exhibit-slides {
    .slick-list {
      .slide-content {
        img {
          margin: 0 auto;
        }
      }
    }
  }

  .gallery-caption {
    background-color: $dark-grey;
    text-align: left;

    div {
      margin: .5rem;
    }
  }

  .faq-header {
    background-color: $primary-blue;
    color: $white;
    font-weight: $bold;
    padding: 0 size(8);
  }

  .footer-slides {
    @extend .full-page;
    @extend .strip-top;
    padding: 1rem;
    background: $white;

    .slick-list {
      @extend .row;
      max-width: $large-breakpoint;
      display: inherit !important;  /*Fix for using flex grid with slick slider*/

      .slick-slide {
        margin: 0 size(8);

        .slide-content {
          display: inline-block;
          background: $grey;
          color: $primary-blue;
          text-align: center;
          max-width: size(329);
          padding: size(8);

          h4 {
            margin: size(10);
            overflow: hidden; /* prevents extra lines from being visible */
          }
        }
      }
    }
  }

  #hof-collection-gallery .row .column {
    padding: 0;
    margin: 0 0 1rem 0;

    .column {
      padding: 0;
      margin: 0 .5rem;
      width: auto;
      height: 100%;
      float: left;
    }
  }

  .magin-bottom-1 {
    margin-bottom: 1rem;
  }

  .site-alert {
    text-align: center;

    .info, .low, .high {
      margin: 0 auto;
      background-image:none !important;
    }
  }

  .slick-dots{
    display:block;
    width:100%;
    padding: $none;
    margin: $none;
    list-style:none;
    text-align:center;
    font-family: $font-awesome;

    li{
      position:relative;
      display:inline-block;
      margin: $none size(5);
      padding: $none;

      a::before {
        content: "\f111";
      }
    }

    .slick-active {
      @extend .fa;
    }
  }

  .support {
    @extend .full-page;
    @extend .center;
    @extend .strip-top;
    @extend .word-style-8;
    background-color: $primary-blue;
    padding: 1rem;

    div {
      display: inline;
      color: $white;
    }

    a {
      border: .125rem solid $white;
      padding: .5rem;
    }

    a:hover {
      background-color: $primary-red;
    }
  }

  .webform p:first-of-type{
    padding-top: 1rem !important;
  }

  .webform-inline-block{
    display: inline-block;
  }

  .webform-label-padding {
    padding-left: 1rem;
  }
}